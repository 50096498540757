import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/our-food',
    name: 'ourfood',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OurFoodView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "about" */ '../views/LegalView.vue')
  },
  {
    path: '/app-download',
    name: 'appdownload',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppDownloadView.vue')
  },
  {
    path: '/returns-policy',
    name: 'returnspolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReturnsPolicy.vue')
  },
  {
    path: '/refund-policy',
    name: 'refundpolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/RefundPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
