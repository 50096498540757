
<template>
  <NavBar/>
  <router-view/>
  <Footer/>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    NavBar,
    Footer
  }
}
</script>

<style>


body {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
 
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}




:root {
    --primary: #cb913c;
    --primary-dark: #ae7c31;
    --accent: #000000;
    --foreground: #4e4e4e;
    --foreground-dark: #1e1e1e;
    --background: #ffffff;
    --background-dark: #eaeaea;
    --footer: #000000;
    --white: #FFFFFF;
    --disabled: #525252;
  }
  
::after,
::before {
    box-sizing: border-box
}

* {
  box-sizing: border-box;
}
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  text-align: left;
   max-width:100%;
   overflow-x:hidden;
   font-size: 16px;

}

.w-100 {
  width:100%;
}

.text-center {
  text-align: center;
}

body {
  background-color: var(--background);
  color: var(--foreground);
  margin: 0;
  padding: 0;
  max-width:100%;
  overflow-x:hidden;
}

a {
  text-decoration: none;
  color: var(--foreground);
}

a:hover {
  color:var(--primary);
}

.btn {
  transition: background-color 0.3s;
  outline:none;
  height:50px;
  border-radius: 25px;
  padding:0 1.25rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  max-width: 200px;
}

.title-lg {
  font-size:50px;
  font-weight: bold;
  line-height: 45px;
  margin:0 0 1.25rem 0;
}

.texture-separator {
  display: flex;
  overflow-x:hidden;
  position: absolute;
  max-width: 100%;
}

.btn-icon {
  background-color: transparent;
  border:none;
  width:40px;
  height:40px;
}

.btn-icon img {
  width:40px;
  height:40px;
}


.btn-primary {
  background-color: var(--primary);
  color:var(--white);
  border:none;
}

.btn-primary:not(.disabled):hover {
  background-color:var(--primary-dark);
  color:var(--white);
}
.btn-primary.disabled {
  background-color: var(--disabled);
  cursor:default
}

.btn-icon {
  background-color: transparent;
  border:none;
  width:40px;
  height:40px;
}

.btn-icon img {
  width:40px;
  height:40px;
}

.btn-white {
  background-color: var(--white);
  color:var(--foreground-dark);
  border:none;
}

.btn-white:hover {
  background-color:#dbdbdb;
}

.btn-secondary {
  background-color: transparent;
  color:var(--white);
  border:2px solid var(--white);
}

.btn-secondary:hover {
  background-color: #00000039;
  color:var(--white);
}

.btn-link {
  color:var(--primary);
  font-weight: bold;
}

.fw-bold {
  font-weight: bold;
}


h1,h2,h3,h4,h5,h6,p {
  margin:0;
}

.text-primary {
  color:var(--primary);
}

.text-white {
  color:var(--white);
}

.text-foreground-dark {
  color:var(--foreground-dark);
}

.header-separator {
  width: 100px;
  height:1px;
  background-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media (max-width:800px) {

p {
  font-size:14px;
}


}

</style>
